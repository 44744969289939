<template>
  <form class="form-info" :class="{skeleton: !state.loaded}" @submit.prevent="submit()">
    <div class="wrapper">
      <div class="head">
        <div class="title">푸시 알림 {{ modalParams.type === "add" ? "추가" : state.push.status === "발송" ? "확인" : "수정" }}</div>
        <button class="remove btn" type="button" title="삭제" @click="remove(modalParams.todoSeq)" v-if="modalParams.type !== 'add' && state.push.status !== '발송'">
          <i class="fa fa-trash"></i>
        </button>
      </div>
      <div class="body">
        <div class="form-group">
          <label class="font-xs" :for="`${component.name}Title`">
            <span class="color-purple">*</span>
            <span> 제목</span>
          </label>
          <input :id="`${component.name}Title`" v-model="state.push.title" type="text" class="form-control"
                 placeholder="ex) 오마이컴퍼니에서 알려드립니다." autocomplete="off" maxlength="100"/>
        </div>
        <div class="form-group">
          <label class="font-xs" :for="`${component.name}Content`">
            <span class="color-purple">*</span>
            <span> 내용</span>
          </label>
          <textarea :id="`${component.name}Content`" v-model="state.push.content" type="text" class="form-control" @keyup.ctrl.enter="submit()"
                    maxlength="255" placeholder="ex) 이벤트는 12월 31일까지 진행합니다." autocomplete="off"></textarea>
        </div>
        <div class="form-group check" v-if="modalParams.type === 'add'">
          <span>즉시 발송</span>
          <label class="switch" :for="`${component.name}DirectFlag`" @click="changeDirectFlag()">
            <input type="checkbox" :checked="state.push.directFlag === 'Y'">
            <span class="slider round"></span>
          </label>
        </div>
        <template v-if="(modalParams.type === 'add' && state.push.directFlag === 'N') || modalParams.type === 'edit'">
          <div class="form-group">
            <label class="font-xs" :for="`${component.name}SendDate`">
              <span class="color-purple">*</span>
              <span> 발송일</span>
            </label>
            <Date componentNameSuffix="S" :id="`${component.name}SendDate`" class="font-sm" placeholder="발송일" :value.sync="state.push.sendDate" format="yyyy-MM-dd"/>
          </div>
          <div class="form-group mb-0">
            <label class="font-xs" :for="`${component.name}SendTime`">
              <span class="color-purple">*</span>
              <span> 발송 시간</span>
            </label>
          </div>
          <select :id="`${component.name}SendTime`" class="form-control border-focus-point font-sm" v-model="state.push.sendTime" v-if="state.push.status !== '발송'">
            <option :value="$lib.getNumberWithPadding(i - 1) + ':00'" v-for="i in 24" :key="i">{{ $lib.getNumberWithPadding(i - 1) }}:00</option>
          </select>
          <input :id="`${component.name}SendTime`" v-model="state.push.sendTime" type="text" class="form-control" autocomplete="off" maxlength="100" v-else/>
        </template>
      </div>
    </div>
    <div class="action" v-if="state.push.status !== '발송'">
      <button class="btn btn-purple btn-block" type="submit">저장하기</button>
    </div>
  </form>
</template>

<script>
import {defineComponent, onMounted, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import lib from "@/scripts/lib";
import $lib from "@/scripts/lib";
import http from "@/scripts/http";
import Date from "@/components/Date.vue";

function Component(initialize) {
  this.name = "modalPush";
  this.initialize = initialize;
}

export default defineComponent({
  computed: {
    $lib() {
      return $lib;
    }
  },
  components: {Date},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      if (modalParams.push) {
        state.push = lib.getRenewed(modalParams.push);
      }

      store.commit("tuneModal", {component, size: "sm"});
    });

    const state = reactive({
      loaded: true,
      push: {
        title: "",
        content: "",
        sendDate: "",
        sendTime: "00:00",
        status: "",
        directFlag: "N",
      }
    });

    const modalParams = store.getters.modalParams(component);

    const submit = () => {
      if (!state.push.title?.trim()) {
        document.getElementById(`${component.name}Title`)?.focus();
        return store.commit("setSwingMessage", "제목을 입력해주세요.");
      }

      if (!state.push.content?.trim()) {
        document.getElementById(`${component.name}Content`)?.focus();
        return store.commit("setSwingMessage", "내용을 입력해주세요.");
      }

      if (state.push.directFlag === "N") {
        if (!state.push.sendDate?.trim()) {
          document.getElementById(`${component.name}SendDate`)?.focus();
          return store.commit("setSwingMessage", "발송일을 입력해주세요.");
        }

        if (!state.push.sendDate?.trim()) {
          document.getElementById(`${component.name}SendTime`)?.focus();
          return store.commit("setSwingMessage", "발송 시간을 입력해주세요.");
        }
      }

      const onClose = () => {
        store.commit("setSwingMessage", "푸시 알림을 저장하였습니다.");
        close();
      };

      if (modalParams.type === "add") {
        http.post("/api/admin/push", state.push).then(onClose);
      } else {
        http.put(`/api/admin/push/${state.push.id}`, state.push).then(onClose);
      }
    };

    const close = () => {
      store.commit("closeModal", {
        name: component.name,
        onClose(modal) {
          store.dispatch("callback", {modal});
        }
      });
    };

    const remove = () => {
      store.commit("confirm", {
        message: "삭제하시겠습니까?",
        allow() {
          http.delete(`/api/admin/push/${modalParams.push.id}`).then(() => {
            store.commit("setSwingMessage", "푸시 알림을 삭제하였습니다.");
            close();
          });
        }
      });
    };

    const changeDirectFlag = () => {
      return state.push.directFlag === "Y" ? state.push.directFlag = "N" : state.push.directFlag = "Y";
    };

    onMounted(() => {
      modalParams.type === "add" && document.getElementById(`${component.name}Title`)?.focus();
    });

    return {component, state, modalParams, submit, close, remove, changeDirectFlag,};
  }
});
</script>

<style lang="scss" scoped>
.form-info {
  > .wrapper {
    background: #fff;
    padding: $px25;
  }

  .head {
    position: relative;
    margin-bottom: $px20;

    .title {
      font-size: $px18;
    }

    .remove {
      position: absolute;
      top: 0;
      right: 0;
      padding: $px9 $px5;
      margin: $px-5;
    }
  }

  .body {
    .form-group {
      font-size: $px14;

      input, textarea, select {
        background-color: #fff;
        font-size: $px13;
        transition: none;
      }

      input, textarea {
        padding: $px15;
      }

      &.check {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $px10;
        border: $px1 solid $colorBorder;
        border-radius: $px4;
        height: $formHeight;

        .switch {
          position: relative;
          display: inline-block;
          width: $px50;
          height: $px21;
          margin-bottom: 0;

          > input {
            opacity: 0;
            width: 0;
            height: 0;

            &:checked + .slider {
              background-color: $colorPurple;

              &::before {
                transform: translate($px29, -50%);
              }
            }

            &:focus + .slider {
              box-shadow: 0 0 $px1 #2196F3;
            }
          }

          > .slider {
            cursor: pointer;
            background-color: #ccc;
            display: inline-block;
            position: relative;
            width: 100%;
            height: 100%;
            transition: .4s;

            &.round {
              border-radius: $px50;

              &::before {
                border-radius: 50%;
              }
            }

            &::before {
              position: absolute;
              content: "";
              height: $px13;
              width: $px13;
              left: $px4;
              top: 50%;
              transform: translateY(-50%);
              background-color: #fff;
              transition: 0.18s;
            }
          }
        }
      }
    }
  }

  .action {
    .btn {
      padding: $px15;
      height: $px60;
      border-radius: 0;
      font-size: $px13;

      &.remove {
        background: $colorSecondary;
        border-color: $colorSecondary;
        color: #fff;

        &:hover {
          background: $colorSecondaryActive;
          border-color: $colorSecondaryActive;
        }
      }
    }
  }

  &.skeleton {
    .head .remove {
      display: none;
    }

    .body {
      input, textarea, select {
        @include skeleton;
      }
    }
  }
}
</style>