<template>
  <div class="date" :class="classList">
    <input type="text" :id="id" class="form-control border-focus-point" :class="inputClass" :placeholder="placeholder" @click="!disabled && open()" :value="value" readonly :disabled="disabled"/>
    <template v-if="!disabled">
      <i class="fa fa-close pointer" @click="remove()" title="삭제" v-if="value"></i>
      <i class="fa fa-calendar" :class="{ pointer: !disabled }" aria-hidden="true" title="달력 열람" @click="open()" v-else></i>
    </template>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import lib from "@/scripts/lib";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";

function Component(initialize) {
  this.name = "componentDate";
  this.initialize = initialize;
  this.checkDuplicatedName = true;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    id: String,
    inputClass: String,
    placeholder: String,
    value: String,
    func: Function,
    disabled: Boolean,
    classList: String,
    format: {
      type: String,
      default: "yyyyMMdd"
    },
  },
  setup(props, {emit}) {
    const component = new Component(() => {
    });

    const update = (d) => {
      const formattedDate = d?.value ? lib.getDateFormat(d.value, props.format) : "";
      emit("update:value", formattedDate);
      typeof props.func === "function" && props.func();
    };

    const remove = () => {
      update(null);
    };

    const open = () => {
      store.commit("openModal", {
        name: "Calendar",
        params: {
          value: props.value,
          format: props.format
        },
        callback: `${component.name}.update`
      });
    };

    return {component, update, open, remove};
  }
});
</script>

<style lang="scss" scoped>
.date {
  position: relative;

  input[type=text] {
    background: none;
    font-size: inherit;
    height: $formHeight;

    &[disabled] {
      background-color: #e9ecef;
    }
  }

  > i {
    position: absolute;
    top: 50%;
    right: $px11;
    margin-top: $px-6;
    color: #3a3a3a;
  }
}
</style>